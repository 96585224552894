$theme-color: #233d63;
$theme-color-2: #51be78;
$theme-color-3: #42be6f;
$theme-color-4: #7f8897;
$theme-color-5: #F7FAFD;
$theme-color-6: #0e0e1e;
$theme-color-7: #9ea6ba;
$white: #fff;
$black: #000;
$color: #eee;
$theme-font: 'Mukta', sans-serif;
$default-font: 16px;
$default-font-2: 15px;
$default-font-3: 14px;
$default-font-4: 13px;
$default-font-5: 12px;
$default-font-6: 11px;
$primary-font: 40px;
$display: 90px;
$display-2: 80px;
$display-3: 70px;
$display-4: 60px;
$display-5: 50px;
$display-6: 40px;
$display-7: 30px;
$display-8: 28px;
$display-9: 26px;
$display-10: 24px;
$display-11: 22px;
$display-12: 20px;
$display-13: 18px;
$display-14: 17px;

$font-weight-thin: 100;
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-black: 800;
$alter-color: #7E3CF9;
$alter-color-2: #F68A03;
$alter-color-3: #358FF7;
$alter-color-4: #38BB0C;
$alter-color-5: #DD493D;
$alter-color-6: #3E5B99;
$alter-color-7: #3AAAE1;
$color: #eee;
$color-2: #BDBBBC;


$laptop_ls: 'only screen and (min-width: 320px) and (max-width: 1919px)';
$laptop_l: '(max-width: 1440px)';
$laptop_m: '(max-width: 1366px)';
$laptop_m_two: '(max-width: 1280px)';
$laptop_m_three: '(max-width: 1200px)';
$laptop_m_four: '(max-width: 1199px)';
$laptop_m_five: '(max-width: 1024px)';
$laptop_m_six: '(min-width: 1200px)';
$medium_device: 'only screen and (min-width: 992px) and (max-width: 1200px)';
$tab_device: 'only screen and (min-width: 768px) and (max-width: 991px)';
$tab_device-and-mobile: 'only screen and (min-width: 320px) and (max-width: 990px)';
$tab_device_two: '(max-width: 768px)';
$tab_device_three: '(max-width: 991px)';
$tab_device_four: '(min-width: 991px)';
$large_mobile: 'only screen and (min-width: 480px) and (max-width: 767px)';
$large_mobile_two: ' (max-width: 600px)';
$large_mobile_three: ' (max-width: 575px)';
$large_mobile_four: ' (max-width: 480px)';
$small_mobile: 'only screen and (min-width: 320px) and (max-width: 479px)';
$small_mobile_one: ' (max-width: 425px)';
$small_mobile_two: '(max-width: 400px)';
$small_mobile_three: '(max-width: 320px)';
$small_mobile_four: '(max-width: 375px)';

$device-1600: '(max-width: 1600px)';
$device-767: '(max-width: 767px)';
$device-992: '(max-width: 992px)';
$device-767: '(max-width: 767px)';
$device-481: '(max-width: 481px)';
$device-384: '(max-width: 384px)';

