/*======== contact-form-action =========*/
.label-text {
  font-size: $default-font-2;
  color: $theme-color;
  font-weight: $font-weight-semi-bold;
  position: relative;
}
.contact-form-action {
  .form-control {
    height: auto;
    width: 100%;
    line-height: inherit;
    padding: 10px 20px 10px 45px;
    font-size: $default-font;
    color: $theme-color;
    border-color: rgba(127,136,151,0.2);
    font-weight: $font-weight-regular;
    background-color: $white;
    @include box-shadow(0 0 0 0);
    @include transition(0.3s);
    @include border-radius(5px);
    &:focus {
      border-color: $theme-color-2;
    }
  }
  .message-control {
    height: 150px;
  }
  .form-group {
    margin-bottom: 20px;
    position: relative;
    .submit-btn {
      position: absolute;
      top: 50%;
      @include transform(translateY(-50%));
      right: 0;
      padding: 10px;
      background-color: transparent;
      border: none;
      color: $theme-color-4;
      font-size: $display-12;
    }
    .input-icon {
      position: absolute;
      top: 15px;
      left: 20px;
      font-size: $display-13;
    }
    .search-close-icon,
    .subscriber-btn,
    .search-icon {
      position: absolute;
      top: 0;
      right: 0;
    }
    .search-close-icon {
      color: $theme-color;
      padding: 15px;
      top: 50%;
      @include transform(translateY(-50%));
      cursor: pointer;
      font-size: $display-13;
    }
    .search-icon {
      color: $theme-color;
      padding: 15px;
      top: 50%;
      @include transform(translateY(-50%) rotate(-90deg));
      cursor: pointer;
      @include transition(0.3s);
      font-size: $display-13;
      background-color: transparent;
      border: none;
      &:hover {
        color: $theme-color-2;
      }
    }
    .subscriber-btn {
      @include border-radius(0 5px 5px 0);
      line-height: 56px;
      border: none;
      &:hover {
        background-color: $alter-color;
        color: $white;
      }
    }
  }
}

/*====================================================
    account-assist
 ====================================================*/
.account-assist {
  .account__desc {
    position: relative;
    font-size: $display-11;
    &:before,
    &:after {
      position: absolute;
      content: '';
      left: 0;
      top: 50%;
      width: 45%;
      height: 1px;
      background-color: rgba(128, 137, 150, 0.2);
      @include transform(translateY(-50%));
    }
    &:after {
      left: auto;
      right: 0;
    }
  }
}

.contact-form-wrap {
  .section-heading {
    @media #{$tab_device} {
      margin-bottom: 50px;
    }
    @media #{$large_mobile} {
      margin-bottom: 50px;
    }
    @media #{$small_mobile} {
      margin-bottom: 50px;
    }
  }
}