/*-===============================
    header-menu-area
===============================-*/
.header-menu-area {
  position: relative;
  z-index: 1023;
  background-color: $white;
  .container-fluid {
    padding-right: 0;
    padding-left: 0;
    @media #{$device-1600} {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}
.header-top {
  border-bottom: 1px solid rgba(127, 136, 151, 0.1);
  padding-right: 185px;
  padding-left: 185px;
  @media #{$device-1600} {
    padding-right: 0;
    padding-left: 0;
  }
  .col-lg-6 {
    @media #{$tab_device} {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    @media #{$tab_device_two} {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
.header-widget {
  ul {
    height: 45px;
    @media #{$tab_device_two} {
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }
    @media #{$small_mobile_three} {
      display: block !important;
      height: 100%;
    }
    li {
      margin-right: 10px;
      display: inline-block;
      &:last-child {
        margin-right: 0;
      }
      a {
        color: $theme-color;
        @include transition(0.3s);
        &:hover {
          color: $theme-color-2;
        }
      }
      .la {
        color: $theme-color-2;
      }
    }
    &.social-profile {
      li {
        a {
          background-color: rgba(81, 190, 120, 0.1);
          color: $theme-color-2;
          &:hover {
            color: $white;
            background-color: $theme-color-2;
          }
        }
      }
    }
    &.contact-info {
      li {
        height: 45px;
        border-right: 1px solid rgba(127, 136, 151, 0.1);
        padding-right: 15px;
        padding-left: 15px;
        padding-top: 10px;
        margin-right: 15px;
        @media #{$tab_device_two} {
          border-right: 0;
          height: auto;
          padding-right: 0;
          padding-top: 0;
        }
        @media #{$small_mobile_three} {
          margin-right: 0;
          padding-right: 0;
          height: auto;
        }
        span {
          margin-right: 2px;
        }
        &:first-child,
        &:last-child {
          padding-left: 0;
        }
        &:last-child {
          @media #{$large_mobile} {
            margin-right: 0;
            padding-right: 0;
          }
          @media #{$small_mobile} {
            margin-right: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
}
.header-widget1 {
  @media #{$small_mobile_three} {
    text-align: center;
    padding-top: 10px;
  }
  .contact-info {
    li {
      @media #{$small_mobile_three} {
        padding-left: 10px !important;
      }
    }
  }
}
.header-widget2 {
  height: 45px;
  @media #{$large_mobile} {
    height: auto;
    padding-bottom: 10px;
  }
  @media #{$small_mobile_three} {
    height: auto;
    margin-top: 10px;
  }
  .header-right-info {
    @media #{$small_mobile_three} {
      width: 100%;
    }
    .social-profile {
      @media #{$small_mobile_three} {
        text-align: center;
      }
    }
    .my-content-dropdown {
      position: absolute;
      z-index: 4;
      right: 0;
      width: 320px;
      height: auto;
      top: 60px;
      padding: 25px;
      background-color: $white;
      @include border-radius(8px);
      @include transition(0.3s);
      @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
      opacity: 0;
      visibility: hidden;
      border: 1px solid rgba(127, 136, 151, 0.2);
      @media #{$large_mobile_three} {
       right: -50px;
      }
      @media #{$large_mobile_four} {
        right: -100px;
      }
      @media #{$small_mobile_three} {
        right: 0;
        width: 290px;
      }
      .cart-info {
        p {
          font-size: $display-14;
          font-weight: $font-weight-bold;
          padding-bottom: 10px;
          color: $theme-color;
        }
        .theme-btn {
          display: block;
          color: $white;
          text-align: center;
          line-height: 40px;
          &:hover {
            color: $theme-color-2;
          }
        }
      }
      .dropdown-menu-item {
        height: auto;
        padding-right: 0;
        padding-left: 0;
        border-right: none;
        .cart-item {
          display: -webkit-flex;
          display: -ms-flex;
          display: flex;
          img {
            width: 60px;
            height: 65px;
            margin-right: 10px;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            @include border-radius(4px);
          }
        }
        .item__info {
          .item__info-link {
            line-height: 20px;
            font-weight: $font-weight-semi-bold;
          }
          .item__price {
            display: block;
            padding-top: 2px;
            font-size: $default-font-2;
          }
        }
        li {
          margin-right: 0;
          margin-bottom: 15px;
          padding-bottom: 15px;
          font-weight: $font-weight-medium;
          color: $theme-color;
          border-bottom: 1px solid rgba(127, 136, 151, 0.2);
          .theme-btn {
            color: $white;
            font-size: $default-font-3;
            line-height: 42px;
            &:hover {
              color: $theme-color-2;
            }
          }
        }
      }
      &:after {
        top: -7px;
        content: "";
        position: absolute;
        background-color: $white;
        right: 20px;
        width: 13px;
        height: 13px;
        @include transform(rotate(45deg));
        @include box-shadow(-2px -2px 10px -5px rgba(0,0,0,.2));
        border-top: 1px solid rgba(127, 136, 151, 0.2);
        border-left: 1px solid rgba(127, 136, 151, 0.2);
        @media #{$large_mobile_three} {
          right: 65px;
        }
        @media #{$large_mobile_four} {
          right: 115px;
        }
        @media #{$small_mobile_three} {
         right: 10px;
        }
      }
    }
    ul {
      border-right: 1px solid rgba(127, 136, 151, 0.1);
      padding-right: 15px;
      padding-left: 15px;
      @media #{$tab_device_two} {
        border-right: 0;
        padding-right: 0;
        padding-left: 0;
      }
      @media #{$large_mobile} {
        border-right: 0;
        padding-right: 0;
        padding-left: 0;
      }
      @media #{$small_mobile_three} {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
      }
      li {
        margin-right: 5px;
        &:last-child {
          margin-right: 0;
        }
      }
      &.user-action {
        padding-right: 0;
        border-right: none;
        @media #{$small_mobile_four} {
          padding-left: 0;
        }
      }
      &.user-cart {
        padding-right: 0;
        padding-left: 0;
        @media #{$small_mobile_three} {
          text-align: right;
        }
        li {
          position: relative;
          .user-cart-btn {
            font-size: $display-10;
            cursor: pointer;
            padding: 7px 15px 13px 15px;
            @media #{$small_mobile_three} {
              padding-right: 0;
            }
          }
          &:hover {
            .my-content-dropdown {
              opacity: 1;
              visibility: visible;
              top: 42px;
            }
          }
        }
      }
    }
    .social-info {
      @media #{$small_mobile_three} {
        display: none !important;
      }
    }
  }
  .header-right-info.d-flex {
    @media #{$small_mobile_three} {
      flex-direction: row-reverse;
    }
  }
}
.header-widget2.d-flex {
  @media #{$small_mobile_three} {
    display: block !important;
  }
}
.header-widget2.justify-content-end {
  @media #{$tab_device_two} {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
}
.header-menu-content {
  padding-right: 185px;
  padding-left: 185px;
  @include transition(0.5s);
  background-color: $white;
  @media #{$device-1600} {
    padding-right: 0;
    padding-left: 0;
  }
}
.main-menu-content {
  height: 100px;
  @media #{$small_mobile_one} {
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .col-lg-3 {
    @media #{$laptop_m_four} {
      -ms-flex: 0 0 30%;
      flex: 0 0 30%;
      max-width: 30%;
    }
    @media #{$tab_device} {
      -ms-flex: 0 0 40%;
      flex: 0 0 40%;
      max-width: 40%;
    }
    @media #{$large_mobile} {
      -ms-flex: 0 0 85%;
      flex: 0 0 85%;
      max-width: 85%;
    }
    @media #{$small_mobile} {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .col-lg-9 {
    @media #{$laptop_m_four} {
      -ms-flex: 0 0 70%;
      flex: 0 0 70%;
      max-width: 70%;
    }
    @media #{$tab_device} {
      -ms-flex: 0 0 60%;
      flex: 0 0 60%;
      max-width: 60%;
    }
    @media #{$large_mobile} {
      -ms-flex: 0 0 15%;
      flex: 0 0 15%;
      max-width: 15%;
    }
    @media #{$small_mobile} {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
.logo-box {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  @media #{$small_mobile_three} {
    display: block;
  }
  .logo {
    @media #{$tab_device} {
      display: inline-block;
    }
    @media #{$large_mobile} {
      display: inline-block;
    }
    @media #{$small_mobile} {
      display: inline-block;
    }
    @media #{$small_mobile_three} {
      margin-bottom: 20px;
      display: block;
      text-align: center;
    }
  }
  .header-category {
    margin-left: auto;
    margin-right: 4px;
    @media #{$small_mobile_one} {
      margin-right: 0;
      margin-left: 40px;
    }
    @media #{$small_mobile_four} {
      margin-left: 30px;
    }
    @media #{$small_mobile_three} {
      margin-left: 0;
    }
    ul {
      li {
        position: relative;
        a {
          font-size: $default-font-2;
          color: $theme-color;
          text-transform: uppercase;
          font-weight: $font-weight-medium;
          padding-bottom: 41px;
          @include transition(0.3s);
          .fa-th {
            font-size: $default-font-3;
          }
          &:hover {
            color: $theme-color-2
          }
        }
        .dropdown-menu-item {
          position: absolute;
          left: 0;
          top: 65px;
          width: 320px;
          background-color: $white;
          display: block;
          padding: 25px 0 25px 0;
          @include border-radius(8px);
          @include transition(0.3s);
          @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
          opacity: 0;
          visibility: hidden;
          border: 1px solid rgba(127, 136, 151, 0.2);
          @media #{$large_mobile} {
            left: auto;
            right: 0;
            overflow-x: hidden;
            height: 370px;
          }
          @media #{$small_mobile} {
            left: auto;
            right: 0;
            overflow-x: hidden;
            height: 370px;
          }
          @media #{$small_mobile_one} {
            width: 270px;
          }
          @media #{$small_mobile_three} {
            width: 290px;
          }
          li {
            margin-bottom: 8px;
            &:last-child {
              margin-bottom: 0;
            }
            .menu-collapse {
              position: absolute;
              right: 20px;
              top: -5px;
              padding: 10px;
              background-color: transparent;
              cursor: pointer;
              @include transition(0.3s);
              @include border-radius(30px);
              &:hover {
                background-color: rgba(81, 190, 120, 0.1);
                color: $theme-color-2;
              }
            }
            a {
              display: block;
              padding-right: 30px;
              padding-left: 30px;
              padding-bottom: 0;
            }
            .sub-menu {
              position: absolute;
              top: -24px;
              left: 318px;
              width: 280px;
              background-color: $white;
              padding: 25px 0 25px 0;
              @include border-radius(4px);
              @include transition(0.3s);
              @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
              @include transform(scale(0.8));
              visibility: hidden;
              opacity: 0;
              @media #{$large_mobile} {
                padding: 0;
                margin-top: 10px;
                opacity: 1;
                visibility: visible;
                left: auto;
                top: auto;
                position: inherit;
                width: 100%;
                display: none;
                @include transform(scale(1));
                @include transition(0s);
                @include box-shadow(0 0 0 0);
              }
              @media #{$small_mobile} {
                padding: 0;
                margin-top: 10px;
                left: auto;
                top: auto;
                position: inherit;
                width: 100%;
                display: none;
                @include transform(scale(1));
                @include transition(0s);
                @include box-shadow(0 0 0 0);
              }
              li {
                margin-bottom: 5px;
                &:last-child {
                  margin-bottom: 0;
                }
                a {
                  color: $theme-color-4;
                  text-transform: capitalize;
                  position: relative;
                  display: inline-block;
                  padding-right: 0;
                  padding-left: 0;
                  margin-left: 30px;
                  &:after {
                    width: 0;
                    height: 1px;
                    position: absolute;
                    content: '';
                    bottom: 5px;
                    right: 0;
                    background-color: $theme-color-2;
                    @include transition(0.3s);
                  }
                  &:hover {
                    color: $theme-color-2;
                    &:after {
                      width: 100%;
                      right: auto;
                      left: 0;
                    }
                  }
                }
              }
              &.active {
                display: block;
              }
            }
            &:hover {
              .sub-menu {
                opacity: 1;
                visibility: visible;
                @include transform(scale(1));
              }
            }
            &.active {
              .menu-collapse {
                @include transform(rotate(90deg));
                color: $white;
                background-color: $theme-color-2;
              }
              a {
                @media #{$large_mobile} {
                  color: $theme-color-2;
                }
                @media #{$small_mobile} {
                  color: $theme-color-2;
                }
              }
            }
          }
          &:after {
            top: -7px;
            content: "";
            position: absolute;
            background-color: $white;
            left: 30px;
            width: 13px;
            height: 13px;
            @include transform(rotate(45deg));
            @include box-shadow(-2px -2px 10px -5px rgba(0,0,0,.2));
            border-top: 1px solid rgba(127, 136, 151, 0.2);
            border-left: 1px solid rgba(127, 136, 151, 0.2);
            @media #{$large_mobile} {
              left: auto;
              right: 30px;
            }
            @media #{$small_mobile} {
              left: auto;
              right: 30px;
            }
          }
        }
        &:hover {
          .dropdown-menu-item {
            opacity: 1;
            visibility: visible;
            top: 43px;
          }
        }
      }
    }
  }
}
.menu-wrapper {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  .contact-form-action {
    width: 500px;
    margin-right: 35px;
    @media #{$laptop_m_four} {
      width: 100%;
    }
    @media #{$tab_device} {
      width: 380px;
    }
    @media #{$large_mobile} {
      display: none;
    }
    @media #{$small_mobile} {
      display: none;
    }
    form {
      .form-group {
        margin: 0;
        .form-control {
          padding-left: 20px;
        }
      }
    }
  }
}
.main-menu {
  margin-right: 35px;
  @media #{$laptop_m_four} {
    display: none;
  }
  ul {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    li {
      margin-right: 34px;
      text-transform: uppercase;
      font-weight: $font-weight-medium;
      position: relative;
      @media #{$laptop_m_four} {
        margin-right: 20px;
      }
      a {
        font-size: $default-font-2;
        color: $theme-color;
        @include transition(0.3s);
        position: relative;
        padding-bottom: 40px;
        font-weight: $font-weight-semi-bold;
        &:after {
          width: 0;
          height: 1px;
          position: absolute;
          content: '';
          bottom: 42px;
          right: 0;
          background-color: $theme-color-2;
          @include transition(0.3s);
        }
        &:hover {
          color: $theme-color-2;
          &:after {
            width: 100%;
            right: auto;
            left: 0;
          }
        }
      }
      &:last-child {
        margin-right: 0;
      }
      .dropdown-menu-item {
        position: absolute;
        left: 0;
        top: 65px;
        width: 225px;
        background-color: $white;
        display: block;
        padding: 25px 30px 25px 30px;
        @include border-radius(8px);
        @include transition(0.3s);
        @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
        border: 1px solid rgba(127, 136, 151, 0.2);
        opacity: 0;
        visibility: hidden;
        li {
          display: block;
          margin-right: 0;
          margin-bottom: 4px;
          text-transform: capitalize;
          &:last-child {
            margin-bottom: 0;
          }
          a {
            color: $theme-color-4;
            display: inline-block;
            position: relative;
            padding-bottom: 0;
            font-weight: $font-weight-medium;
            &:after {
              bottom: 5px;
            }
            &:hover {
              color: $theme-color-2;
            }
          }
        }
        &:after {
          top: -7px;
          content: "";
          position: absolute;
          background-color: $white;
          left: 30px;
          width: 13px;
          height: 13px;
          @include transform(rotate(45deg));
          @include box-shadow(-2px -2px 10px -5px rgba(0,0,0,.2));
          border-top: 1px solid rgba(127, 136, 151, 0.2);
          border-left: 1px solid rgba(127, 136, 151, 0.2);
        }
      }
      &:hover {
        .dropdown-menu-item {
          @include transform(scale(1));
          opacity: 1;
          visibility: visible;
          top: 53px;
        }
      }
    }
  }
}
.logo-right-button {
  ul {
    @media #{$laptop_m_four} {
      display: none;
    }
  }
  .user-action {
    li {
      display: inline-block;
      text-transform: uppercase;
      margin-right: 4px;
      &:last-child {
        margin-right: 0;
      }
      a {
        display: block;
        color: $theme-color;
        @include transition(0.3s);
        &:hover {
          color: $theme-color-2;
        }
      }
      .theme-btn {
        color: $white;
        line-height: 40px;
        &:hover {
          color: $theme-color-2;
        }
      }
    }
  }
  .user-menu-open,
  .side-menu-open {
    width: 50px;
    height: 50px;
    line-height: 56px;
    font-size: $display-6;
    color: $theme-color;
    cursor: pointer;
    text-align: center;
    @include border-radius(5px);
    display: none;
    position: relative;
    z-index: 1;
    background-color: rgba(127, 136, 151, 0.2);
    @include transition(0.3s);
    @media #{$laptop_m_four} {
      display: block;
    }
    @media #{$small_mobile_one} {
      margin-top: -45px;
    }
    &:hover {
      background-color: $theme-color-2;
      color: $white;
    }
  }
  .user-menu-open {
    display: none;
    @media #{$tab_device} {
      display: block;
    }
    @media #{$large_mobile} {
      display: block;
    }
    @media #{$small_mobile} {
      display: block;
    }
  }
}
.logo-right-button-2 {
  border-left: 1px solid rgba(127, 136, 151, 0.1);
  padding-left: 35px;
  @media #{$laptop_m_four} {
    padding-left: 0;
    border-left: none;
  }
}
.user-nav-container,
.dashboard-nav-container,
.side-nav-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 320px;
  height: 100%;
  background-color: $theme-color;
  overflow-x: hidden;
  z-index: 999;
  @include transition(0.4s cubic-bezier(0.7, 0, 0.2, 1));
  @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
  @include transform(translateX(-100%));
  .humburger-menu {
    .humburger-menu-lines {
      display: inline-block;
      text-align: right;
      width: 22px;
      height: 22px;
      cursor: pointer;
      position: absolute;
      top: 25px;
      right: 18px;
      opacity: .5;
      &:before,
      &:after {
        position: absolute;
        content: '';
        height: 2px;
        width: 100%;
        top: 10px;
        left: 0;
        background-color: $white;
        @include transform(rotate(-43deg));
        @include transition(0.3s);
      }
      &:after {
        @include transform(rotate(43deg));
      }
      &:hover {
        &:before,
        &:after {
          @include transform(rotate(0));
        }
      }
    }
  }
  .side-menu-wrap {
    margin-top: 100px;
    .side-menu-ul {
      .sidenav__item {
        position: relative;
        @include transition(0.3s);
        a {
          color: $white;
          text-transform: uppercase;
          display: block;
          padding: 14px 20px;
          @include transition(0.3s);
          font-weight: $font-weight-medium;
        }
        .menu-plus-icon {
          position: absolute;
          top: 0;
          right: 0;
          width: 50px;
          height: 56px;
          line-height: 56px;
          cursor: pointer;
          @include transition(0.3s);
          &:before,
          &:after {
            position: absolute;
            top: 24px;
            right: 20px;
            content: '';
            width: 11px;
            height: 2px;
            background-color: $white;
            @include transform(rotate(-90deg));
            @include transition(0.3s);
          }
          &:after {
            @include transform(rotate(0));
          }
          &:hover {
            background-color: rgba(255, 255, 255, 0.10);
          }
        }
        .side-sub-menu {
          display: none;
          li {
            border-bottom: 1px solid rgba(255, 255, 255, 0.12);
            &:first-child {
              border-top: 1px solid rgba(255, 255, 255, 0.12);
            }
            a {
              padding-left: 30px;
              padding-top: 12px;
              padding-bottom: 12px;
              position: relative;
              text-transform: capitalize;
              &:hover {
                color: $theme-color-2;
              }
            }
          }
        }
        &:after {
          position: absolute;
          content: '';
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: rgba(255, 255, 255, 0.12);
        }
        &:hover {
          > a {
            background-color: rgba(255, 255, 255, 0.12);
          }
        }
        &.active {
          .menu-plus-icon {
            &:before {
              @include transform(rotate(0deg));
            }
          }
        }
      }
    }
  }
  .side-btn-box {
    text-align: center;
    margin-top: 40px;
    .theme-btn {
      line-height: 40px;
      background-color: rgba(255, 255, 255, 0.1);
      border: none;
      color: $white;
      &:hover {
        background-color: $theme-color-2;
      }
    }
    span {
      display: inline-block;
      margin-right: 10px;
      margin-left: 10px;
      font-size: $display-11;
    }
  }
  &.active {
    @include transform(translateX(0));
  }
}

.user-nav-container {
  left: auto;
  right: 0;
  background-color: $white;
  overflow-x: hidden;
  z-index: 999;
  @include transform(translateX(100%));
  @media #{$small_mobile_three} {
    width: 320px;
  }
  .humburger-menu {
    .humburger-menu-lines {
      top: 10px;
      width: 18px;
      height: 18px;
      &:before,
      &:after {
        background-color: $theme-color;
      }
    }
  }
  .section-tab {
    .nav-tabs {
      padding: 50px 20px 0 20px;
      li {
        margin-bottom: 0;
      }
    }
  }
  .user-panel-content {
    margin-top: 10px;
  }
  .user-sidebar-item {
    .mess__body {
      max-height: inherit;
      overflow-x: inherit;
      overflow-y: inherit;
    }
  }
  .user-action-item {
    .mess__title {
      border: none;
    }
  }
  &.active {
    @include transform(translateX(0));
  }
}
/*-===============================
    header-menu-area2
===============================-*/
.header-menu-area2 {
  padding-right: 0;
  padding-left: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);;
  .header-top {
    border-bottom: transparent;
    background-color: $white;
    .header-widget2 {
      .header-right-info {
        ul {
          &.user-cart {
            li {
              .dropdown-menu-item {
                li {
                  .theme-btn {
                    @include border-radius(30px);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .main-menu-content {
    .logo-box {
      .header-category {
        margin-right: 0;
        margin-left: 60px;
        ul {
          li {
            a {
              color: $white;
              text-transform: capitalize;
              .fa-th {
                margin-right: 2px;
              }
            }
            .dropdown-menu-item {
              li {
                a {
                  color: $theme-color;
                  &:hover {
                    color: $theme-color-2;
                  }
                }
              }
            }
          }
        }
      }
    }
    .menu-wrapper {
      .main-menu {
        ul {
          li {
            text-transform: capitalize;
            a {
              color: $white;
            }
          }
        }
      }
      .logo-right-button {
        .social-info {
          li {
            margin-right: 5px;
            &:last-child {
              margin-right: 0;
            }
            a {
              color: $white;
              @include transition(0.3s);
              display: block;
              width: 38px;
              height: 38px;
              line-height: 38px;
              text-align: center;
              @include border-radius(50%);
              background-color: rgba(255, 255, 255, 0.1);
              &:hover {
                color: $theme-color-2;
                background-color: $white;
              }
            }
          }
        }
      }
    }
  }
}

/*---------------- fixed-nav ----------------*/
.fixed-nav .header-top {
  height: 0;
  border: none;
  opacity: 0;
  visibility: hidden;
}
.fixed-nav .header-menu-content {
  position: fixed;
  top: 0;
  width: 100%;
  @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
  .main-menu-content {
    @include transition(0.5s);
    .logo-box {
      .header-category {
        ul {
          li {
            &:hover {
              .dropdown-menu-item {
                top: 50px;
              }
            }
          }
        }
      }
    }
    .menu-wrapper {
      .main-menu {
        ul {
          li {
            &:hover {
              .dropdown-menu-item {
                top: 50px;
              }
            }
          }
        }
      }
    }
  }
}

/*=========== my-course-menu-content ===========*/
.my-course-menu-content {
  .logo-box {
    @media #{$laptop_m_four} {
      width: 250px;
    }
  }
  .menu-wrapper {
    .contact-form-action {
      margin-right: 0;
    }
  }
}
/*=========== course-dashboard-header ===========*/
.course-dashboard-header {
  background-color: $theme-color;
}
.course-dashboard-title {
  flex: 1;
  margin-left: 40px;
  color: $white;
  @media #{$tab_device_two} {
    margin-left: 10px;
    line-height: 25px;
  }
  @media #{$large_mobile} {
    display: inline-block;
  }
  @media #{$large_mobile_three} {
    display: block;
    margin-left: 0;
  }
  a {
    color: $white;
  }
}
.course-dashboard-menu-content {
  padding-right: 0;
  padding-left: 0;
  background-color: $theme-color;
  .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }
  .logo-box {
    width: 200px;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    @media #{$tab_device_two} {
      width: 160px;
    }
    @media #{$large_mobile} {
      width: auto;
      display: inline-block;
      border-right: none;
    }
    @media #{$large_mobile_three} {
      width: 100%;
      display: block;
      border-right: none;
      margin-bottom: 10px;
    }
  }
  .main-menu-content {
    height: 80px;
    @media #{$large_mobile} {
      display: block !important;
      height: auto;
      padding-top: 15px;
      padding-bottom: 15px;
      text-align: center;
    }
    @media #{$large_mobile_three} {
      display: block !important;
      height: auto;
      padding-top: 15px;
      padding-bottom: 15px;
      text-align: center;
    }
  }
  .menu-wrapper {
    width: 30%;
    @media #{$laptop_m_five} {
      width: auto;
    }
    @media #{$large_mobile} {
      padding-top: 10px;
      display: block;
    }
    @media #{$large_mobile_three} {
      padding-top: 10px;
      display: block;
    }
  }
  .logo-right-button {
    @media #{$large_mobile_three} {
      width: 100%;
    }
    ul {
      @media #{$laptop_m_four} {
        display: block;
      }
      @media #{$large_mobile} {
        -ms-flex-pack: justify;
        justify-content: center;
      }
      @media #{$small_mobile} {
        -ms-flex-pack: justify;
        justify-content: center;
      }
      li {
        margin-right: 14px;
        &:last-child {
          margin-right: 0;
        }
        .msg-action-dot {
          .action-dot {
            display: block;
            color: $white;
            font-size: $display-7;
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background-color: rgba(127, 136, 151, 0.1);
            border: 1px solid rgba(127, 126, 151, 0.2);
            @include border-radius(4px);
            &:hover {
              background-color: $theme-color-2;
            }
          }
          .dropdown-menu {
            margin-top: 45px;
            right: 0;
            left: auto !important;
          }
        }
      }
    }
    .theme-btn {
      color: $white;
      line-height: 40px;
      @media #{$small_mobile_three} {
        padding-right: 10px;
        padding-left: 10px;
      }
    }
  }
}
/*======== main-menu-content-2 =======*/
.main-menu-content-2 {
  .logo-box {
    .logo {
      @media #{$small_mobile_three} {
        margin-bottom: 0;
        display: inline-block;
        text-align: left;
      }
    }
  }
}







