/*==================================
    CATEGORY
 ==================================*/
.category-area {
  .section-heading {
    @media #{$large_mobile} {
      text-align: center;
    }
    @media #{$small_mobile} {
      text-align: center;
    }
  }
  .btn-box.justify-content-end {
    @media #{$tab_device} {
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }
    @media #{$large_mobile} {
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }
    @media #{$small_mobile} {
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }
  }
}

.category-wrapper {
  @media #{$tab_device} {
    margin-top: 60px;
  }
  @media #{$large_mobile} {
    margin-top: 60px;
  }
  @media #{$small_mobile} {
    margin-top: 60px;
  }
}
.category-item {
  @include border-radius(4px);
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  img {
    width: 100%;
    @include border-radius(4px);
    @include transition(0.3s);
  }
  .category-content {
    position: absolute;
    text-align: center;
    padding-top: 50px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    color: $white;
    @include transition(0.3s);
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .cat__title {
    color: $white;
    text-transform: capitalize;
    font-size: $display-11;
    @include transition(0.3s);
    font-weight: $font-weight-bold;
    a {
      color: $white;
    }
  }
  .cat__meta {
    margin-top: 4px;
    text-transform: capitalize;
    @include transition(0.3s);
  }
  .theme-btn {
    background-color: $white;
    color: $theme-color;
    line-height: 40px;
    padding-right: 20px;
    padding-left: 20px;
    @include transition(0.3s);
    font-size: $default-font-4;
    opacity: 0;
    visibility: hidden;
    border-color: $white;
    &:hover {
      background-color: transparent;
      color: $white;
    }
  }
  &:after {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: $theme-color;
    opacity: .80;
    content: "";
    @include border-radius(4px);
    @include transition(0.3s);
  }
  &:hover {
    .category-content {
      padding-top: 0;
    }
    .cat__meta {
      opacity: 0;
      visibility: hidden;
      margin: 0;
    }
    .theme-btn {
      opacity: 1;
      visibility: visible;
    }
    img {
      @include transform(scale(1.1) rotate(3deg));
    }
  }
}

.category-item-layout-2 {
  background-color: $white;
  @include border-radius(4px);
  border: 1px solid rgba(127, 136, 151, 0.2);
  overflow: hidden;
  .category-content {
    color: $theme-color;
    position: inherit;
    top: auto;
    left: auto;
    @include transform(translate(0, 0));
    display: block;
    padding: 40px 0 40px 0;
  }
  .icon-element {
    color: $theme-color-2;
    width: 60px;
    height: 60px;
    line-height: 60px;
    background-color: rgba(81, 190, 120, 0.1);
    font-size: $display-7;
  }
  .cat__title {
    color: $theme-color;
    margin-top: 20px;
    font-size: $display-12;
  }
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../images/img31.jpg");
    background-size: cover;
    background-position: center;
    background-color: transparent;
    @include border-radius(4px);
    opacity: 0;
    visibility: hidden;
  }
  &:before,
  &:after {
    @include transform(scale(1.1));
    opacity: 0;
    visibility: hidden;
    @include transition(0.6s);
  }
  &:hover {
    border-color: transparent;
    .category-content {
      padding-top: 40px;
    }
    .icon-element{
      background-color: $white;
    }
    .cat__title {
      color: $white;
    }
    &:after {
      opacity: .80;
    }
    &:before,
    &:after {
      @include transform(scale(1));
      visibility: visible;
    }
    &:before {
      opacity: 1;
    }
  }
}